import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const AddProject = () => {
  return (
    <LayoutComponent>
      <div>
        <h4>Add project and link with a Jira project (Admins Only)</h4>
        <p>
          Only organization administrators have the ability to create new
          projects. To add a project, follow these steps:
        </p>
        <ol>
          <li>Log in to your GAINSS account as an administrator.</li>
          <li>
            Navigate to the <strong>Projects</strong> section from the dashboard
            or main menu.
          </li>
          <li>
            Click on <strong>Add New Project</strong>.
          </li>
          <li>
            Fill in the required details in the project creation form:
            <ul>
              <li>
                <strong>Project Name</strong>: Provide a name for the new
                project.
              </li>
              <li>
                <strong>Description</strong>: Optionally, add a brief
                description of the project.
              </li>
            </ul>
          </li>
          <li>
            If you have a project management tool like Jira integrated with
            GAINSS, you will also have the option to link a Jira project to this
            GAINSS project. This allows you to search for existing issues or
            create new ones in Jira directly from within GAINSS.
          </li>
          <li>
            Once all details are filled in, click <strong>Create</strong> to
            finalize the project setup.
          </li>
        </ol>
        <p>
          Once a project is added, it will appear in the project list, where
          admins can further manage it and assign users as needed.
        </p>
      </div>
    </LayoutComponent>
  )
}

export default AddProject
